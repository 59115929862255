<template>
	<div class="inner">
		<img src="../assets/news-banner.png" width="100%" alt="" />
		<div class="container">
			<div class="all-title over mt50 pt20 pb16 el-border-bottom mb20">
				<div class="fl ft36 bold">资讯动态</div>
				<div class="fl ft24 bl9 ml12 mt12"> / News</div>
			</div>
			<div class="tc news-nav ft20 mt40">
				<el-row :gutter="12">
					<el-col :span="6" v-for="(item,index) in newsNav.list" :key="index">
						<div class="item tr3" @click="changeNav(index)" :class="newsNav.current==index?'nav-select':''">
							{{item.name}}
						</div>
					</el-col>
				</el-row>
			</div>
			<div @click="handleDetails(item.id)" class="news-list tr3 hover el-border-bottom pb30 mb30 mt30 over"
				v-for="(item,index) in newsList" :key="index">
				<div class="news-list-img fls0 fl">
					<div class="news-list-img-inner">
						<img :src="item.mainPic" alt="" width="100%" height="150px">
					</div>
				</div>
				<div class="ml20 fl news-content">
					<div class="ft24 line1 mb14">{{item.title}}</div>
					<div class="ft20 bl9 line2 l16 tj">{{item.shortDesc}}</div>
					<div class="ft18 bl9 mt14 over">
						<span>{{item.category == 1 ? '企业新闻' : item.category == 2 ? '行业动态' : '企业公告'}}</span>
						<span class="fr">{{item.updateTime}}</span>
					</div>
				</div>
			</div>
			<div class="tc pb50">
				<el-pagination background layout="prev, pager, next" :total="total"  :current-page="queryParams.pageNum" :page-size="queryParams.pageSize"  @current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import { getNews} from '@/api/index.js'
	export default {
		data() {
			return {
				queryParams: {
					category: '',
					pageNum: 1,
					pageSize: 5
				},
				total: 0,
				newsList: [],
				newsNav: {
					list: [{
						name: '全部'
					}, {
						name: '企业新闻'
					}, {
						name: '行业动态'
					}, {
						name: '企业公告'
					}],
					current: 0
				}
			}
		},
		created(){
			this.getNewsInfo()
		},
		methods: {
			//导航切换
			changeNav(index) {
				this.newsNav.current = index
				this.queryParams.category = index
				this.getNewsInfo()
			},
			getNewsInfo(){
				getNews(this.queryParams).then(res => {
					if(res.code === 200){
						this.newsList = res.data.list;
						this.total = res.data.count;
					}
				})
			},
			handleCurrentChange(val){
				this.queryParams.pageNum = val
				this.getNewsInfo()
			},
			handleDetails(id){
				this.$router.push({
					path: '/news-details',
					query:{
						id: id
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.news-nav .item {
		width: 100%;
		border-radius: 100px;
		border: 1px solid #bfbfbf;
		padding: 12px 0;
		margin-bottom: 20px;
		color: #666666;
		cursor: pointer;
	}

	.news-nav .item.nav-select,
	.news-nav .item:hover {
		border-color: #B5232E;
		background-color: #B5232E;
		color: #FFFFFF;
	}

	.news-list:hover {
		color: #B5232E;
		transform: translateX(4px);
	}

	.news-list .news-list-img {
		overflow: hidden;
		border-radius: 10px;
		width: 204px;
		height: 150px;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		background-color: #ddd;
	}

	.news-list .ml20 {
		width: 80%;
	}
</style>
